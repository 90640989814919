import { render, staticRenderFns } from "./BrowserUpdatePrompt.vue?vue&type=template&id=79fe95d1&scoped=true&"
import script from "./BrowserUpdatePrompt.vue?vue&type=script&lang=ts&"
export * from "./BrowserUpdatePrompt.vue?vue&type=script&lang=ts&"
import style0 from "./BrowserUpdatePrompt.vue?vue&type=style&index=0&id=79fe95d1&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79fe95d1",
  null
  
)

export default component.exports