








import { Component, Vue } from 'vue-property-decorator'
import { setTheme } from '@/theme'
import { Theme } from '@/types/Theme'
import VerticalLineSplitList from '@/components/common/VerticalLineSplitList.vue'

@Component({
  components: { VerticalLineSplitList }
})
export default class ThemeSwitcher extends Vue {
  private theme: Theme = 'light'
  private darkModeMediaQuery: MediaQueryList

  constructor () {
    super()
    this.darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.theme = 'light'
    // this.theme = <"light" | "dark" | "auto">this.$store.state.stores.themeStore.get() || 'auto'
  }

  private get themes () {
    return ['light', 'dark', 'auto'].map(t => ({
      label: this.$t(`common.theme.${t}`),
      value: t
    }))
  }

  /**
   * 主题切换事件处理程序
   */
  private colorThemeChangeHandler () {
    if (this.theme !== 'auto') return
    this.themeChangeHandler('auto')
  }

  /**
   * 切换主题
   * @param theme selected theme
   */
  private themeChangeHandler (theme: Theme) {
    // if (theme === this.theme) return
    this.$store.state.stores.themeStore.set(theme)
    if (theme === 'auto') theme = this.darkModeMediaQuery.matches ? 'dark' : 'light'
    setTheme(theme)
  }

  private mounted () {
    // 这里的兼容性判断不能使用 `MediaQueryList.prototype.addEventListener` 判断, 因为 Safari 没有 MediaQueryList 这个变量！
    if (this.darkModeMediaQuery.addEventListener) {
      this.darkModeMediaQuery.addEventListener('change', this.colorThemeChangeHandler)
    } else {
      this.darkModeMediaQuery.addListener(this.colorThemeChangeHandler)
    }
    this.themeChangeHandler(this.theme)
  }

  private destroyed () {
    if (this.darkModeMediaQuery.addEventListener) {
      this.darkModeMediaQuery.removeEventListener('change', this.colorThemeChangeHandler)
    } else {
      this.darkModeMediaQuery.removeListener(this.colorThemeChangeHandler)
    }
  }
}
