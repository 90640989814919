
















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BrowserUpdatePrompt extends Vue {
  public dialogVisible: boolean = navigator.userAgent.includes('Trident') // 屏蔽 IE
  private browserList = [
    { name: 'chrome', href: 'https://www.google.cn/chrome/' },
    { name: 'firefox', href: 'http://www.firefox.com.cn' }
  ]
}
