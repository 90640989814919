

















import { Component, Vue, Prop } from 'vue-property-decorator'

type LabelValueItem = {
  label: string
  value: number | string
}

@Component
export default class VerticalLineSplitList extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ required: true }) data!: LabelValueItem[]

  /**
   * 切换选项
   * @param item selected item
   */
  private itemChangeHandler (item: LabelValueItem) {
    if (item.value === this.value) return
    this.$emit('input', item.value)
    this.$emit('item-change', item)
  }
}
